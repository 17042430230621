<template>
	<div>
		<h1>vue-my-photos Demo</h1>
		<div id="filters">
			<form>
				<fieldset>
					<legend>Filter</legend>
					<span>
						<input
							type="radio"
							id="all"
							name="filters"
							checked
							v-on:click="updateFilter('all')"
						/>
						<label for="all">All</label>
					</span>
					<span>
						<input
							type="radio"
							id="animals"
							name="filters"
							v-on:click="updateFilter('animals')"
						/>
						<label for="animals">Animals</label>
					</span>
					<span>
						<input
							type="radio"
							id="natue"
							name="filters"
							v-on:click="updateFilter('nature')"
						/>
						<label for="nature">Nature</label>
					</span>
				</fieldset>
			</form>
		</div>

		<transition-group name="thumbnailfade" tag="div">
			<img
			loading="lazy" decoding="async"
				v-for="thumb in filteredImages"
				:key="thumb.id"
				@click="showLightbox(thumb.name)"
				:src="thumbnailDir + thumb.name"
				:alt="thumb.alt"
				:title="thumb.alt"
			/>
		</transition-group>

		<lightbox
			id="mylightbox"
			ref="lightbox"
			:images="images"
			:directory="thumbnailDir"
			:filter="'all'"
			:timeoutDuration="5000"
		/>
	</div>
</template>

<script>
// import Tinybox from 'vue-tinybox';


import lightbox from './lightbox.vue';

import { onMounted } from 'vue';

export default {
	name: 'itemgallery',

	components: { lightbox },

	data() {
		return {
			thumbnailDir: '/photo/',
			images: imageList,
			galleryFilter: 'all',
		};
	},

	props: {
		gallery: Object,
	},
	methods: {
		showLightbox: function (imageName) {
			this.$refs.lightbox.show(imageName);
		},
		updateFilter(filterName) {
			this.galleryFilter = filterName;
		},
	},

	computed: {
		filteredImages: function () {
			if (this.galleryFilter === 'all') {
				return this.images;
			} else {
				return this.images.filter(
					(image) => image.filter === this.galleryFilter
				);
			}
		},
	},

	setup(props, { slots }) {
		console.log('this', this);

		// console.log(this.gallery);

		// console.log('this', this.$slots);
		/*
		console.log(
			'li component setup slots: ',
			JSON.stringify(slots),
			' i props: ',
			JSON.stringify(props)
		);

		*/

		onMounted(() => {
			console.log('li component mounted', this);
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
li {
	list-style: none;
	text-align: left;
}
h2 {
	color: aqua;
}
</style>
