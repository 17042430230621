<template>
	<li>
		<h2><slot name="title">Dupa</slot></h2>
		<p><slot name="body">Dupa</slot></p>
		<p v-if="none"><slot name="none">Nic</slot></p>
	</li>
</template>

<script>
	import { onMounted } from 'vue';

	export default {
		name: 'licomponent',

		props:{
			none: Boolean
		},

		setup(props, { slots }) {
			// console.log('this', this.$slots);

			console.log(
				'li component setup slots: ',
				JSON.stringify(slots),
				' i props: ',
				JSON.stringify(props)
			);

			onMounted(() => {
				console.log('licomponent this mounbted', this);
			});
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
	li {
		list-style: none;
		text-align: left;
	}
	h2 {
		color: aqua;
	}
</style>
