<template>

	<template v-if="item">

<div class="head">
		<h1 class="name" v-if="item.name">{{ item.name }}</h1>
</div>

<div class="inside">

		<dl class="story">
			<div class="item description" v-if="item.description">
				<dd class="content" v-html="$filters.nl2br($filters.format(item.description))"></dd>
			</div>

			<div class="item" v-for="(text, index) in item.sections" :key="index">
				<dt v-if="text.title" class="title">{{text.title}}</dt>
				<dd v-if="text.text" class="content" v-html="'<p>'+$filters.nl2p($filters.makeLink(text.text))"></dd>
			</div>

			<div class="item" v-if="item.gal && item.gal.length">
				<dt class="title">Galeria</dt>
				<dd class="content">
					<ul class="group">
						<li
							v-for="(src, index) in item.gal"
							:key="index"
							class="group-item"
						>
							<a
								:href="item.gal[index].src"
								@click.prevent="() => showImg(index)"
								class="item"
							>
								<img
							loading="lazy" decoding="async"
									class="pic"
									:alt="item.gal[index].title"
									:src="item.gal[index].pic"
								/>
								<span v-if="item.gal[index].title" class="name">{{
									item.gal[index].title
								}}</span>




								
								
							</a>
						</li>
					</ul>
				</dd>
			</div>

			<div class="item bib" v-if="item.bib">
<dt class="title">Bibliografia</dt>
				<dd class="content" v-html="$filters.nl2br(item.bib)"></dd>
			</div>
			<div class="item url" v-if="item.url">
<dt class="title">Linki</dt>
				<dd class="content" v-html="$filters.makeOnlyLink(item.url)"></dd>
			</div>

		</dl>

<aside class="right" v-if="item.images && item.images.length">

					<ul  v-if="item.images" class="images" >

						<li
							v-for="(src, index) in item.images"
							:key="index"
							class="group-item"
						>
							<a
								:href="item.images[index].src"
								@click.prevent="() => showImg(index)"
								class="item"
							>
								<img
									loading="lazy" decoding="async"
									class="pic"
									:alt="item.images[index].title"
									:src="item.images[index].pic"
								/>
								<span v-if="item.images[index].title" class="name">{{
									item.images[index].title
								}}</span>
								<span v-if="item.images[index].date" class="date"> ({{
									item.images[index].date
								}})</span>
								<span v-if="item.images[index].orig" class="orig"> źródło: {{
									item.images[index].orig
								}}</span>
							</a>
						</li>
					</ul>



</aside>
</div>

	</template>

	<vue-easy-lightbox
		:visible="visible"
		:imgs="item.images"
		:index="photoIndex"
		:moveDisabled="true"
		@hide="handleHide"
	></vue-easy-lightbox>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import licomponent from '../components/licomponent.vue';
import gallery from '../components/itemgallery.vue';
import itemgallery from '../components/itemgallery.vue';

// console.log('licomponent', licomponent);

export default {
	components: { itemgallery,VueEasyLightbox },
	name: 'itemmedium',

	props: {
		cat: String,
		gal: Array,
		item: Object,
	},

	data() {
		return {
			slug: this.$route.params.slug,
			visible: false,
			photoIndex:0,
		};
	},

	computed: {
		category() {
			// on ten computed uruchamia dwa razu z jakiegos powodu
			if (this.$store.state.categories[this.cat]) {
				// on w tym store ma jakies obiekty kompletne, tego nie potrzebujemu

				this.makeGroups(
					JSON.stringify(this.$store.state.categories[this.cat].items)
				);

				//				console.log('computed this.$store.state', JSON.stringify(this.$store.state.categories));

				this.categoryTitle = this.$store.state.categories[this.cat].title;

				// return this.$store.state.categories[this.cat].items;
				return '.';
			} else {
				return false;
			}
		},
	},


	methods: {
		

		showImg(index) {
			this.photoIndex = index;
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},		

		makeGallery(gal) {

			let images = [];

			for (let g = 0, ga = gal.length; g < ga; g += 1) {

				let imgPath = '/photo/default.jpg';

				if (gal[g].img) {
					imgPath = '/photo/' + gal[g].img + '.jpg';
				}

				images.push(imgPath);

			}


			return images;
		},
	},

	beforeMount() {

	},

	mounted() {
		// console.log('categoru',JSON.stringify(this.cat));
		// console.log('MOUNTED', this.$store);
		// tu wysyła że teraz pobierz posty i...
		//this.categoryClass = this.cat;
		// if(!this.$store.state.categories[this.cat]){
		// this.$store.dispatch('getCategory',{"category":this.cat});
		// }
		// this.$store.dispatch('getCategory',{"category":this.cat});
		// console.log('slodt', JSON.stringify(this.$store.state.categories[this.cat]));
	},
};
</script>

<style lang="less">
</style>
